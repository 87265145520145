import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, children }) => {
    const location = useLocation(); // Get the current location
    if (!isAuthenticated) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }
    return children;
};

export default ProtectedRoute;


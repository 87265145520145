import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Login = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState(""); // To display success or error message
    const [showForm, setShowForm] = useState(true); // To toggle visibility of login form

    const navigate = useNavigate(); // For navigation
    const location = useLocation(); // To get the current page

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/login`, { username, password });
            const token = response.data.token;
            console.log("Token received:", token);

            // Store the token in localStorage
            localStorage.setItem("token", token);

            // Show success message and hide the form
            setMessage("Login successful!");
            setShowForm(false);

            // Notify parent of login success
            setTimeout(() => {
                onLoginSuccess();

                // Redirect to the previous page or home if no previous page
                const redirectTo = location.state?.from?.pathname || "/";
                navigate(redirectTo);

                setMessage(""); // Clear the message
            }, 3000); // Adjust timing as necessary
        } catch (error) {
            console.error("Login error:", error.response?.data || error.message);
            setMessage("Invalid username or password");
        }
    };

    return (
        <div className="login-container">
            <h2>Logga in</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Användarnamn:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Lösenord:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Logga in</button>
                {message && <p style={{ color: "red" }}>{message}</p>}
            </form>

            {/* Registration prompt */}
            <div className="register-prompt">
                <span>Saknar du konto?</span>
                <Link to="/register" className="register-button">
                    Registrera
                </Link>
            </div>
        </div>
    );
};

export default Login;

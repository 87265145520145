import React, { useEffect, useState } from "react";
import axios from "axios";
import './Style.css';

const TeamLadder = () => {
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    useEffect(() => {
        const fetchTeamLadder = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${API_BASE_URL}/team-ladder`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTeams(response.data);
            } catch (error) {
                console.error("Error fetching team ladder:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamLadder();
    }, []);

    return (
        <div>
            <h2>Lagstege</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Lag</th>
                            <th>Lagrating</th>
                            <th>Matcher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {teams.map((team) => (
                            <tr key={team.TeamID}>
                                <td>{team.TeamRank}</td>
                                <td>{team.TeamName}</td>
                                <td>{team.CombinedRating}</td>
                                <td>{team.MatchesPlayed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default TeamLadder;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Navigation = ({ isAuthenticated, handleLogout }) => {
    const [matchesDropdownVisible, setMatchesDropdownVisible] = useState(false);
    const [laddersDropdownVisible, setLaddersDropdownVisible] = useState(false);
    const navigate = useNavigate();

    // Function to close all dropdowns
    const closeDropdowns = () => {
        setMatchesDropdownVisible(false);
        setLaddersDropdownVisible(false);
    };

    const handleLogoutClick = () => {
        handleLogout();
        closeDropdowns();
        navigate("/"); // Redirect to home after logout
    };

    return (
        <nav style={menuStyle}>
            {/* Ladders Dropdown - Always visible */}
            <div
                style={dropdownStyle}
                onMouseEnter={() => setLaddersDropdownVisible(true)}
                onMouseLeave={() => setLaddersDropdownVisible(false)}
            >
                <span style={mainMenuStyle}>Stegar</span>
                {laddersDropdownVisible && (
                    <div style={dropdownContentStyle}>
                        <Link to="/" style={dropdownLinkStyle} onClick={closeDropdowns}>
                            Individuell stege
                        </Link>
                        <Link to="/team-ladder" style={dropdownLinkStyle} onClick={closeDropdowns}>
                            Lagstege
                        </Link>
                    </div>
                )}
            </div>

            {/* Matches Dropdown */}
            <div
                style={dropdownStyle}
                onMouseEnter={() => setMatchesDropdownVisible(true)}
                onMouseLeave={() => setMatchesDropdownVisible(false)}
            >
                <span style={mainMenuStyle}>Matcher</span>
                {matchesDropdownVisible && (
                    <div style={dropdownContentStyle}>
                        {isAuthenticated ? (
                            <Link to="/register-match" style={dropdownLinkStyle} onClick={closeDropdowns}>
                                Registrera match
                            </Link>
                        ) : (
                            <span style={{ ...dropdownLinkStyle, color: "gray", cursor: "not-allowed" }}>
                                Registrera match
                            </span>
                        )}
                        <Link to="/match-history" style={dropdownLinkStyle} onClick={closeDropdowns}>
                            Matchhistorik
                        </Link>
                    </div>
                )}
            </div>

            {/* Stats Link */}
            <Link to="/stats" style={mainMenuStyle}>
                Statistik
            </Link>

            {/* Authentication Links */}
            <div style={authLinkGroupStyle}>
                {!isAuthenticated ? (
                    <>
                        <Link to="/login" style={authLinkStyle}>
                            Logga in
                        </Link>
                    </>
                ) : (
                    <Link to="#" onClick={handleLogoutClick} style={authLinkStyle}>
                        Logga ut
                    </Link>
                )}
            </div>
        </nav>
    );
};

// Styles
const menuStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px",
    backgroundColor: "#333",
    color: "#fff",
};

const dropdownStyle = {
    position: "relative",
    marginRight: "20px",
};

const dropdownContentStyle = {
    position: "absolute",
    top: "100%",
    left: 0,
    backgroundColor: "#fff",
    color: "#000",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    zIndex: 1000,
    display: "block",
};

const dropdownLinkStyle = {
    textDecoration: "none",
    color: "#000",
    padding: "10px 20px",
    display: "block",
    whiteSpace: "nowrap",
};

const mainMenuStyle = {
    color: "#fff",
    textDecoration: "none",
    cursor: "pointer",
    fontWeight: "bold",
    padding: "10px",
    fontSize: "16px",
};

const authLinkGroupStyle = {
    marginLeft: "auto", // Pushes the authentication links to the far right
    display: "flex",
    gap: "10px",
};

const authLinkStyle = {
    color: "#ffcc00",
    textDecoration: "none",
    fontWeight: "bold",
};

export default Navigation;

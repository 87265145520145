import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Ladder from "./Ladder";
import TeamLadder from "./TeamLadder";
import MatchHistory from "./MatchHistory";
import Login from "./Login";
import ProtectedRoute from "./ProtectedRoute";
import AddMatchForm from "./AddMatchForm";
import Navigation from "./Navigation";
import Register from "./Register";
import Stats from "./Stats";
import axios from "axios";
import './Style.css';


function App() {
    const [refreshLadder, setRefreshLadder] = useState(false); // To refresh the ladder after adding a match
    const [showPopup, setShowPopup] = useState(false); // For the match registration popup
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Track login status
    const [isTokenValidated, setIsTokenValidated] = useState(false); // Ensure validation completes before rendering
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    const handleMatchAdded = () => {
        setRefreshLadder(!refreshLadder); // Refresh the ladder
        setShowPopup(false); // Close the popup
    };

    const handleLogout = () => {
        localStorage.removeItem("token"); // Clear the token
        setIsAuthenticated(false); // Update authentication state
    };

    // Validate the token with the backend on app start
    useEffect(() => {
        const validateToken = async () => {
            const token = localStorage.getItem("token");
            if (!token) {
                setIsAuthenticated(false);
                setIsTokenValidated(true);
                return;
            }

            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/verify-token`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.status === 200) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error("Error validating token:", error);
                setIsAuthenticated(false);
            } finally {
                setIsTokenValidated(true); // Allow rendering after validation
            }
        };

        validateToken();
    }, []);

    if (!isTokenValidated) {
        // Show a loading screen while validating the token
        return <div>Loading...</div>;
    }

    const handleLoginSuccess = () => {
        setIsAuthenticated(true);
    };

    

    return (
        <Router>
            <div>
                <h1>Kungälvs padelstege 2025</h1>
                <Navigation isAuthenticated={isAuthenticated} handleLogout={handleLogout} />
                <Routes>
                    <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/" element={<Ladder refresh={refreshLadder} />} />
                    <Route path="/team-ladder" element={<TeamLadder />} />
                    <Route path="/match-history" element={<MatchHistory />} /> {/* Publicly accessible */}
                    <Route path="/stats" element={<Stats />} />
                  
                    <Route
                        path="/register-match"
                        element={
                            <ProtectedRoute isAuthenticated={isAuthenticated}>
                                <AddMatchForm onMatchAdded={handleMatchAdded} />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
            </div>
        </Router>
    );
}

export default App;

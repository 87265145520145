import React, { useState } from "react";
import axios from "axios";

const Register = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

        if (password !== confirmPassword) {
            setMessage("L�senorden matchar inte."); // "Passwords do not match."
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/register`, { username, password });
            setMessage("Registreringen lyckades!"); // "Registration successful!"
            setUsername("");
            setPassword("");
            setConfirmPassword("");
        } catch (error) {
            console.error("Error during registration:", error);
            if (error.response && error.response.data) {
                setMessage(`Fel: ${error.response.data}`); // "Error: ..." from server
            } else {
                setMessage("Ett fel uppstod vid registreringen."); // "An error occurred during registration."
            }
        }
    };

    return (
        <div style={styles.container}>
            <h2>Registrera dig</h2> {/* "Register" in Swedish */}
            <form onSubmit={handleSubmit} style={styles.form}>
                <div>
                    <label>Anv&auml;ndarnamn:</label> {/* Username */}
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        style={styles.input}
                    />
                </div>
                <div>
                    <label>L&ouml;senord:</label> {/* Password */}
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        style={styles.input}
                    />
                </div>
                <div>
                    <label>Bekr&auml;fta l&ouml;senord:</label> {/* Confirm Password */}
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                        style={styles.input}
                    />
                </div>
                <button type="submit" style={styles.button}>Registrera</button> {/* Register */}
            </form>
            {message && <p style={styles.message}>{message}</p>}
        </div>
    );
};

const styles = {
    container: { maxWidth: "400px", margin: "0 auto", textAlign: "center" },
    form: { display: "flex", flexDirection: "column", gap: "10px" },
    input: { padding: "8px", fontSize: "16px" },
    button: { padding: "10px", fontSize: "16px", cursor: "pointer" },
    message: { marginTop: "10px", fontSize: "14px", color: "red" },
};

export default Register;

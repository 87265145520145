import React, { useEffect, useState } from "react";
import axios from "axios";
import './Style.css';

const MatchHistory = () => {
    const [matches, setMatches] = useState([]);
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';


    useEffect(() => {
        const fetchMatchHistory = async () => {
            try {
                const token = localStorage.getItem("token"); // Retrieve the token
                const response = await axios.get(`${API_BASE_URL}/match-history`, {
                    headers: {
                        Authorization: `Bearer ${token}`, // Add token to request
                    },
                });
                console.log("Match history data fetched:", response.data); // Debugging log
                setMatches(response.data); // Store fetched matches data
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized: Token may be invalid or expired.");
                    localStorage.removeItem("token"); // Clear invalid token
                    window.location.href = "/login"; // Redirect to login page
                } else {
                    console.error("Error fetching match history data:", error);
                }
            } finally {
                setLoading(false); // Stop loading indicator
            }
        };

        fetchMatchHistory();
    }, []); // Run only once on component load

    return (
        <div>
            <h2>Matchhistorik</h2>
            {loading ? (
                <p>Loading...</p>
            ) : matches.length > 0 ? (
                <table border="1" style={{ width: "100%", textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th>Datum</th>
                            <th>Lag A</th>
                            <th>Resultat</th>
                            <th>Lag B</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {matches.map((match) => (
                            <tr key={match.MatchID}>
                                <td>{new Date(match.MatchDate).toLocaleDateString()}</td>
                                <td>
                                    {match.TeamAPlayers}
                                </td>
                                <td>{match.Result}</td>
                                <td>
                                    {match.TeamBPlayers}
                                </td>
                                
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No match history available.</p>
            )}
        </div>
    );
};

export default MatchHistory;

import React, { useEffect, useState } from "react";
import axios from "axios";
import './Style.css';

const AddMatchForm = ({ onMatchAdded }) => {
    const [formData, setFormData] = useState({
        Player1ID: "",
        Player2ID: "",
        Opponent1ID: "",
        Opponent2ID: "",
        Result: "Win", // Default to 'Win'
        MatchDate: "", // Example format: "2024-11-20"
    });
    const [players, setPlayers] = useState([]); // State to store player list
    const [message, setMessage] = useState("");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    // Fetch player names and IDs on component mount
    useEffect(() => {
        const fetchPlayers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/players`);
                setPlayers(response.data); // Populate player list
            } catch (error) {
                console.error("Error fetching players:", error);
            }
        };

        fetchPlayers();
    }, []);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check for duplicate player IDs
        const { Player1ID, Player2ID, Opponent1ID, Opponent2ID } = formData;
        const selectedPlayers = [Player1ID, Player2ID, Opponent1ID, Opponent2ID];
        const uniquePlayers = new Set(selectedPlayers);

        if (uniquePlayers.size !== selectedPlayers.length) {
            setMessage("Du kan inte välja samma spelare mer än en gång."); // "You cannot select the same player more than once."
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/add-match`, formData);
            setMessage("The match was successfully recorded!"); // Set the success message

            // Wait a few seconds before notifying the parent and closing the popup
            setTimeout(() => {
                setFormData({
                    Player1ID: "",
                    Player2ID: "",
                    Opponent1ID: "",
                    Opponent2ID: "",
                    Result: "Win",
                    MatchDate: "",
                });
                if (onMatchAdded) onMatchAdded(); // Notify parent to refresh the ladder
            }, 2000); // Delay by 2 seconds
        } catch (error) {
            console.error("Error adding match:", error);
            setMessage("Failed to add match. Please try again.");
        }
    };


    return (
        <div>
            <h2>Matchregistrering</h2>
            <form onSubmit={handleSubmit}>
                {/* Player 1 Dropdown */}
                <label>
                    Spelare 1:
                    <select
                        name="Player1ID"
                        value={formData.Player1ID}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Välj Spelare 1</option>
                        {players.map((player) => (
                            <option key={player.PlayerID} value={player.PlayerID}>
                                {player.PlayerName}
                            </option>
                        ))}
                    </select>
                </label>
                

                {/* Player 2 Dropdown */}
                <label>
                    Spelare 2:
                    <select
                        name="Player2ID"
                        value={formData.Player2ID}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Välj Spelare 2</option>
                        {players.map((player) => (
                            <option key={player.PlayerID} value={player.PlayerID}>
                                {player.PlayerName}
                            </option>
                        ))}
                    </select>
                </label>
                <br />

                {/* Opponent 1 Dropdown */}
                <label>
                    Motståndare 1:
                    <select
                        name="Opponent1ID"
                        value={formData.Opponent1ID}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Välj Motståndare 1</option>
                        {players.map((player) => (
                            <option key={player.PlayerID} value={player.PlayerID}>
                                {player.PlayerName}
                            </option>
                        ))}
                    </select>
                </label>
                

                {/* Opponent 2 Dropdown */}
                <label>
                    Motståndare 2:
                    <select
                        name="Opponent2ID"
                        value={formData.Opponent2ID}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Välj Motståndare 2</option>
                        {players.map((player) => (
                            <option key={player.PlayerID} value={player.PlayerID}>
                                {player.PlayerName}
                            </option>
                        ))}
                    </select>
                </label>
                <br />

                {/* Result Dropdown */}
                <label>
                    Resultat:
                    <select
                        name="Result"
                        value={formData.Result}
                        onChange={handleChange}
                        required
                    >
                        <option value="Win">Vinst</option>
                        <option value="Lose">Förlust</option>
                    </select>
                </label>
               

                {/* Match Date */}
                <label>
                    Matchdatum:
                    <input
                        type="date"
                        name="MatchDate"
                        value={formData.MatchDate}
                        onChange={handleChange}
                        required
                    />
                </label>
                <br />

                <button type="submit">Registrera match</button>
            </form>
            {message && <p style={{ color: "green" }}>{message}</p>}
        </div>
    );
};

export default AddMatchForm;


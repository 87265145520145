import React, { useEffect, useState } from "react";
import axios from "axios";
import './Style.css';

const Ladder = () => {
    const [ladder, setLadder] = useState([]);
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

    useEffect(() => {
        const fetchLadder = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await axios.get(`${API_BASE_URL}/ladder`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log("Ladder data fetched:", response.data); // Debug log
                setLadder(response.data); // Store ladder data
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    console.error("Unauthorized: Token may be invalid or expired.");
                    localStorage.removeItem("token");
                    window.location.href = "/login";
                } else {
                    console.error("Error fetching ladder data:", error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchLadder();
    }, []);

    // Function to render movement as an icon
    const renderMovement = (movement) => {
        switch (movement) {
            case "Up":
                return <span style={{ color: "green" }}>⬆</span>; // Green up arrow
            case "Down":
                return <span style={{ color: "red" }}>⬇</span>; // Red down arrow
            case "Same":
                return <span style={{ color: "black" }}>–</span>; // Black hyphen
            default:
                return null;
        }
    };

    return (
        <div>
            <h2>Individuell stege</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <table border="1" style={{ width: "100%", textAlign: "center" }}>
                    <thead>
                        <tr>
                            <th>Ranking</th>
                            <th>Spelare</th>
                            <th>Rating</th>
                            <th>Matcher</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ladder.map((player, index) => (
                            <tr key={index}>
                                <td>{player.CurrentRank} {renderMovement(player.Movement)}</td>
                                <td>{player.PlayerName}</td>
                                <td>{player.CurrentRating}</td>
                                <td>{player.MatchesPlayed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Ladder;

